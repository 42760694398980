// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactus-index-js": () => import("./../src/pages/contactus/index.js" /* webpackChunkName: "component---src-pages-contactus-index-js" */),
  "component---src-pages-giveaway-index-js": () => import("./../src/pages/giveaway/index.js" /* webpackChunkName: "component---src-pages-giveaway-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-deals-index-js": () => import("./../src/pages/latest-deals/index.js" /* webpackChunkName: "component---src-pages-latest-deals-index-js" */),
  "component---src-pages-recommended-gear-index-js": () => import("./../src/pages/recommended-gear/index.js" /* webpackChunkName: "component---src-pages-recommended-gear-index-js" */),
  "component---src-templates-blog-post-contentful-js": () => import("./../src/templates/blog-post-contentful.js" /* webpackChunkName: "component---src-templates-blog-post-contentful-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-contentful-js": () => import("./../src/templates/page-contentful.js" /* webpackChunkName: "component---src-templates-page-contentful-js" */),
  "component---src-templates-person-contentful-js": () => import("./../src/templates/person-contentful.js" /* webpackChunkName: "component---src-templates-person-contentful-js" */),
  "component---src-templates-product-roundup-js": () => import("./../src/templates/product-roundup.js" /* webpackChunkName: "component---src-templates-product-roundup-js" */)
}

